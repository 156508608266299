import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import { VALID_IMAGE } from '@/utils/config'

export default function updateConfig() {
  const status = ref(false)
  const imgSrc = ref('https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Example_image.svg/600px-Example_image.svg.png')
  const arrayRatingConfig = ref([
    {
      name: '',
      languageCode: '',
      slug: '',
      description: '',
    },
  ])
  const countRatingConfig = ref(1)
  const idConfig = ref(null)
  const numberDefault = ref(null)
  const loading = ref(false)
  const peRule = ref({})
  const isActive = ref(true)
  console.log(store.state.peRule)
  // Fetch post
  if (router.currentRoute.params.id) {
    loading.value = true
    idConfig.value = router.currentRoute.params.id
    store
      .dispatch('peRule/fetchPeRule', { id: idConfig.value })
      .then(response => {
        const res = response.body
        peRule.value = {
          name: res.name,
          gender: store.state.peRule.genderOptions.find(x => x.value === res.gender),
          start_age: res.start_age,
          end_age: res.end_age,
          nationality: store.state.peRule.countries.find(x => x.value === res.country_code),
          pePrograms: res.pePrograms
        }
        isActive.value = res.status
        loading.value = false
      })
  }

  return {
    arrayRatingConfig,
    countRatingConfig,
    status,
    imgSrc,
    loading,
    numberDefault,
    peRule,
    isActive
  }
}
